class FilterFieldsResource extends RESTFullResource {
    static endpoint () {
        return 'client/filter-fields';
    }
    static index () {
        return this.request('GET', '/');
    }
    static update (data) {
        return this.request('PUT', '', data);
    }
}
