(function() {
	const getHostElement = () => {
		let host = document.querySelector('.sh-toast-host');

		if (!host) {
			const el = document.createElement('div');
			el.classList.add('sh-toast-host');
			document.body.appendChild(el);
			host = el;
		}

		return host;
	}

	const createToast = (message, type) => {
		const el = document.createElement('div');
		el.classList.add('sh-toast');
		el.classList.add(`sh-toast-${type}`.toLowerCase());
		el.innerHTML = `<div class="sh-toast-content">${renderMessage(message)}</div>`;

		return el;
	}

    const renderMessage = (message) => {
        return String(message)
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/\*\*(.*?)\*\*/gm, '<b>$1</b>');
    }

	function toast (message, type) {
		const el = createToast(message, type || toast.SUCCESS);
		getHostElement().appendChild(el);

		setTimeout(() => {
			el.classList.add('visible');
		}, 50);

		setTimeout(() => {
			el.classList.remove('visible');
			setTimeout(() => getHostElement().removeChild(el), 500);
		}, 5000);
    }
    
    function toastGenerator (type = 'save_generic', e) {
        if (type === 'save_generic') {
            toast(Env.get('ONSAVE_ERROR'), toast.ERROR);
        }

        if (type === 'save_validator') {
            toast(Env.get('VALIDATION_ERROR'), toast.ERROR);
        }

        if (type === 'load_generic') {
            toast(Env.get('ONLOAD_ERROR'), toast.ERROR);
        }

        if (type === 'api_error') {
            if (Array.isArray(e.message)) {
                toast(e.message[0].message, toast.ERROR);
            }

            if (typeof e.message === 'string') {
                toast(e.message, toast.ERROR);
            }
    
        }
    }

	toast.SUCCESS = 'SUCCESS';
	toast.ERROR = 'ERROR';
	toast.INFO = 'INFO';

    window.toast = toast;
    window.toastGenerator = toastGenerator;
})();