class AnalyticsResource extends RESTFullResource {
    static endpoint() {
        return 'client/analytics';
    }
    static index() {
        return this.request('GET');
    }
    static report (type) {
        return this.requestFile('GET', type);
    }
}
