class PersonalDataResource extends RESTFullResource {
    static endpoint() {
        return 'client/profile';
    }
    static index() {
        return this.request('GET', '/');
    }
    static update(data) {
        return this.request('PUT', '/', data);
    }
    static updateProfilePic(input) {
        let imageData = new FormData();
        imageData.append('avatar', input.files[0]);

        return this.request('PUT', '/avatar', imageData);
    }
}
