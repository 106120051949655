// -- DECLARE VENDORS --
// @vendor: 'vue-the-mask/dist/vue-the-mask.js'
// @vendor: 'moment/moment.js'
// @vendor: 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.umd.js'
// @vendor: 'v-tooltip/dist/v-tooltip.umd.js'
// @vendor: 'v-money/dist/v-money.js'
// @vendor: 'vue-select/dist/vue-select.js'
// @vendor: 'vue-multiselect/dist/vue-multiselect.min.js'

// @vendor: 'd3-array/dist/d3-array.js'
// @vendor: 'd3-axis/dist/d3-axis.js'
// @vendor: 'd3-brush/dist/d3-brush.js'
// @vendor: 'd3-collection/dist/d3-collection.js'
// @vendor: 'd3-color/dist/d3-color.js'
// @vendor: 'd3-dispatch/dist/d3-dispatch.js'
// @vendor: 'd3-drag/dist/d3-drag.js'
// @vendor: 'd3-dsv/dist/d3-dsv.js'
// @vendor: 'd3-ease/dist/d3-ease.js'
// @vendor: 'd3-format/dist/d3-format.js'
// @vendor: 'd3-interpolate/dist/d3-interpolate.js'
// @vendor: 'd3-path/dist/d3-path.js'
// @vendor: 'd3-scale/dist/d3-scale.js'
// @vendor: 'd3-selection/dist/d3-selection.js'
// @vendor: 'd3-shape/dist/d3-shape.js'
// @vendor: 'd3-time/dist/d3-time.js'
// @vendor: 'd3-time-format/dist/d3-time-format.js'
// @vendor: 'd3-timer/dist/d3-timer.js'
// @vendor: 'd3-transition/dist/d3-transition.js'
// @vendor: 'd3-zoom/dist/d3-zoom.js'
// @vendor: 'billboard.js/dist/billboard.js'

// -- INITIALIZE VENDORS --
Vue.use(VueTheMask);
Vue.use(moment);
Vue.use('v-tooltip');
Vue.use(VueSelect.VueSelect);
Vue.use(VueMultiselect.Multiselect);

Vue.component('Money', 'v-money');
Vue.component('multiselect', VueMultiselect.Multiselect);
Vue.component('vue-ctk-date-time-picker', window['vue-ctk-date-time-picker']);
Vue.component('v-select', VueSelect.VueSelect);

// -- SET ENVIROMENTS VARIABLES --
//
// ENV.set(KEY, VALUE, ENV = 'GLOBAL')
//
// Examples:
//   Env.set('MY_KEY', 'c4ca4238a0b923820dcc509a6f75849b');
//   Env.set('MY_KEY', 'c81e728d9d4c2f636f067f89cc14862c', 'PROD');
//
Env.set('CURRENT_ENV', 'LOCAL');

// @SH_ENV - START - DONT REMOVE THIS LINE!!

(() => {
    const url = window.location;
    const isRemote = (
        url.host.indexOf('mraholding.com.br') >= 0 || 
        url.host.indexOf('app.fluxstation.com') >= 0 || 
        url.host.indexOf('wdit.in') >= 0
    );

    Env.set(
        'API_URL',
        isRemote ? `${url.protocol}//${url.host}/api/` : 'http://localhost:3333/api/',
    );
})();

//Env.set('API_URL', 'http://stage.mra.wdit.in/api');
Env.set('AUTH_DRIVER', 'jwt');
Env.set('AUTH_ENDPOINT', '/login');
Env.set('AFTER_LOGIN_REDIRECT', '/');
Env.set('AFTER_LOGIN_REDIRECT_ADMIN', '/admin/dashboard');
Env.set('MY_ACCOUNT_PAGE_PATH', '');
Env.set('LOGOUT_ENDPOINT', '/logout');
Env.set('LOGOUT_ENDPOINT_METHOD', 'GET');
Env.set('ONSAVE', 'informações salvas com sucesso.');
Env.set('ONSAVE_ERROR', 'ocorreu um erro ao tentar salvar.');
Env.set('ONLOAD_ERROR', 'ocorreu um erro ao tentar carregar os dados.');
Env.set('ONLOGIN_ERROR', 'não foi possível logar com as credenciais informadas.');
Env.set('PUT_METHOD_SPOOFING', 'querystring');
Env.set('VALIDATION_ERROR', 'preencha os campos corretamente.');
Env.set('LOGIN_PAGE_ROUTE', '/login');
Env.set('FINANCIAL_PAGE_ROUTE', '/config/financial');
Env.set('ADMIN_LOGIN_PAGE_ROUTE', '/admin/login');
Env.set('EMAIL_NOT_FOUND', 'email não encontrado');
// @SH_ENV - END - DONT REMOVE THIS LINE!!

App.start();


/**
 * Vue Filters
 */
Vue.filter('patioConstants', function (value) {
    if (!value) return '';

    switch (value) {
        // Pricing Type
        case 'PER_TON':
            return 'Por Peso';
        case 'PER_NIGHT':
            return 'Por Diária';
        case 'PER_VEHICLE':
            return 'Por Veículo';
        case 'FIXED_VALUE':
            return 'Valor Fixo';

        // Patio status
        case 'INTERNAL':
            return 'Interno';
        case 'SHARED':
            return 'Compartilhado';

        // Patio operations
        case 'PHYSICAL':
            return 'Ponto Físico';
        case 'LOGISTIC':
            return 'Operador Logístico';

        // Billing rules
        case 'ENTRY':
            return 'No Momento da Entrada';
        case 'ENTRY24':
            return 'Após 24 Horas da Entrada';
        case 'ENTRY48':
            return 'Após 48 Horas da Entrada';
    }
});
Vue.filter('flowConstants', function (value) {
    if (!value) return '';

    switch (value) {
        // Booking Cost Type
        case 'COURTESY':
            return 'Cortesia';
        case 'PAYING':
            return 'Pagante';
        
        // Stay type
        case 'INTERNAL':
            return 'Interno';
        case 'EXTERNAL':
            return 'Externo';

        // Status
        case 'ADDED':
            return 'Adicionado';
        case 'WAITING':
            return 'Aguardando';
        case 'PRE_AUTHORIZED':
            return 'Pré-autorizado';
        case 'AUTHORIZED':
            return 'Autorizado';
        case 'REVOKED':
            return 'Revogado';
        case 'UNLOADED':
            return 'Descarregado';
        case 'PARKED':
            return 'Estacionado';
        case 'FINISHED':
            return 'Finalizado';

    }
});
Vue.filter('roles', function (value) {
    if (!value) return '';

    switch (value) {
        case 'ACCOUNT_DIRECTOR':
            return 'Diretor de conta';
        case 'ACCOUNT_MANAGER':
            return 'Gerente de conta';
        case 'PATIO_MANAGER':
            return 'Gerente de pátio';
    }
});
Vue.filter('adminRoles', function (value) {
    if (!value) return '';

    switch (value) {
        case 'SYSTEM_DIRECTOR':
            return 'Diretor de sistema';
        case 'SYSTEM_MANAGER':
            return 'Gestor de sistema';
    }
});
Vue.filter('cpf', function (value) {
    if (!value) return '';

    value = value.padStart(11, '0');
    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return value;
});
Vue.filter('cnpj', function (value) {
    if (!value) return '';

    value = value.padStart(14, '0');
    value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    return value;
});
Vue.filter('date', function (value) {
    if (!value) return '';
    value = value.split('T');
    return moment(value[0]).format('DD/MM/YYYY');
});
Vue.filter('datetime', function (value) {
    if (!value) return '';
    return moment(value).format('DD/MM/YYYY HH:mm:ss');
});
Vue.filter('phone', function (phone) {
    if (!phone) return '';
    
    return phone.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
});
Vue.filter('creditcards', function (value) {
    if (!value) return '';

    switch (value) {
        case 'master':
            return 'Mastercard';
        case 'hipercard':
            return 'Hipercard';
        case 'amex':
            return 'American Express';
        case 'elo':
            return 'Elo';
        case 'debvisa':
            return 'Visa Débito';
        case 'debelo':
            return 'Elo Débito';
        case 'debmaster':
            return 'Mastercard Débito';
        case 'visa':
            return 'Visa';
    }
});
Vue.filter('creditcards_brand', function (value) {
    if (!value) return 'f-icon-credit-card';

    switch (value) {
        case 'master':
            return 'f-icon-mastercard';
        case 'hipercard':
            return 'f-icon-credit-card';
        case 'amex':
            return 'f-icon-credit-card';
        case 'elo':
            return 'f-icon-credit-card';
        case 'debvisa':
            return 'f-icon-visa';
        case 'debelo':
            return 'f-icon-credit-card';
        case 'debmaster':
            return 'f-icon-mastercard';
        case 'visa':
            return 'f-icon-visa';
    }
});
Vue.filter('moneyMask', function (value) {
    switch (value) {
        case 0:
            return '-';
        case null:
            return '-';
        default:
            value = value / 100;
            return 'R$' + value.toFixed(2);
    }
});
Vue.filter('cep', (cepNumber) => {
    var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

	if(re.test(cepNumber)){
		return cepNumber.replace(re,"$1$2-$3");
	}

    return re;
});
Vue.filter('tel', function (number) {
    if (!number) return '';

    return number.replace(/[^0-9]/g, '')
                .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
});