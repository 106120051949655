class ForgotPasswordResource extends RESTFullResource {
    static endpoint() {
        return 'client/password-reset';
    }

    static store(data) {
        return this.request('POST', '/', data);
    }

    static update(data) {
        return this.request('PUT', '/', data);
    }
}
