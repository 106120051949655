class FlowsResource extends RESTFullResource {
    static endpoint () {
        return 'client/flows';
    }

    static index (page = 1, filters) {
        const params = {
            page,
            ...filters
        };
        return this.request('GET', '/', params);
    }
    static show (code) {
        return this.request('GET', code);
    }
    static store (data) {
        return this.request('POST', '/', data);
    }
    static update (code, data) {
        return this.request('PUT', code, data);
    }
    static destroy (code) {
        return this.request('DELETE', code);
    }

    /**
     * Actions
     */
    static authorize (code) {
        return this.request('PUT', `${code}/authorize`);
    }
    static preauthorize (code, data) {
        return this.request('PUT', `${code}/pre-authorize`, data);
    }
    static unload (code) {
        return this.request('PUT', `${code}/unload`);
    }
    static revoke (code) {
        return this.request('PUT', `${code}/revoke`);
    }
    static park (code) {
        return this.request('PUT', `${code}/park`);
    }
    static print (code) {
        return this.requestFile('GET', `${code}/print`);
    }

    /**
     * Export
     */
    static xls (filters) {
        return this.requestFile('GET', `report/xls`, filters);
    }

    /**
     * Send SMS
     */
    static sendSms (data) {
        return this.request('POST', 'sms', data);
    }
}
