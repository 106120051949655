SweetValidator.rule('fullname',  {
    test: (val) => {
        var regName =  /^[A-zÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-zÀ-Ÿ][A-zÀ-ÿ']+$/;
        
        if(!regName.test(val)){
            return false;
        }else{
            return true;
        }
    },

    message: 'Insira o nome completo'
});