class BusinessAccountResource extends RESTFullResource {
    static endpoint() {
        return 'client/business-account';
    }
    static show() {
        return this.request('GET');
    }
    static update(data) {
        return this.request('PUT', '', data);
    }
}
