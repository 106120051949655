class CitiesResource extends RESTFullResource {
    static endpoint () {
        return 'resources/cities';
    }

    static countries () {
        return this.request('GET');
    }

    static states (country) {
        return this.request('GET', country);
    }

    static cities (country, state) {
        return this.request('GET', `${country}/${state}`);
    }
}
