// Router.set('/', 'home');
// Router.set('/', 'home', { middleware: ['auth'] });
// Router.set('/', 'home', { name: 'home' });
// Router.group({ prefix: 'users', middleware: ['auth']}, ({ set }) => {
//     set('password', 'home', { middleware: ['acl', 'auth'] });
// });

// @SW_ROUTES - START

/**
 * Auth routes
 */
Router.set('/login', 'login');
Router.set('/forgot-password', 'forgotpassword-form');
Router.set('/forgot-password/:token', 'forgotpassword-change-form');
Router.set('/activate-account/:token', 'activate-account');

/**
 * Client routes
 */
Router.set('/', 'dashboard', { middleware: ['auth', 'client'] });
Router.set('/clients', 'clients', { middleware: ['auth', 'client'] });
Router.set('/destinations', 'destinations', { middleware: ['auth', 'client'] });
Router.set('/drivers', 'drivers', { middleware: ['auth', 'client'] });
Router.set('/products', 'products', { middleware: ['auth', 'client'] });
Router.set('/flows', 'flows', { middleware: ['auth', 'client'] });
Router.set('/panel', 'panel', { middleware: ['auth', 'client'] });
Router.set('/patios', 'patios', { middleware: ['auth', 'client'] });
Router.set('/indicators-analysis', 'indicators-analysis-list', { middleware: ['auth', 'client'] });

Router.set('/config/company', 'company-config', { middleware: ['auth', 'client'] });
Router.set('/config/users', 'users-config', { middleware: ['auth', 'client'] });

Router.set('/config/me', 'me', { middleware: ['auth', 'client'] });
Router.set('/config/me/form', 'me-form', { middleware: ['auth', 'client'] });
Router.set('/config/me/password', 'me-password', { middleware: ['auth', 'client'] });

Router.set('/config/user/form', 'user-form-view-config', { middleware: ['auth', 'client'] });
Router.set('/config/user/:code', 'user-view-config', { middleware: ['auth', 'client'] });
Router.set('/config/user/:code/form', 'user-form-view-config', { middleware: ['auth', 'client'] });
Router.set('/config/user/:code/password', 'user-password-config', { middleware: ['auth', 'client'] });

Router.set('/config/table-flow-patio', 'table-flow-patio-config', { middleware: ['auth', 'client'] });
Router.set('/config/financial', 'financial-config', { middleware: ['auth', 'client'] });

Router.set('/t/:ticket', 'ticket-show');

/**
 * Admin routes
 */
Router.set('/admin/login', 'login-admin');

Router.set('/admin/dashboard', 'dashboard-admin', { middleware: ['auth', 'admin'] });
Router.set('/admin/users', 'users-admin', { middleware: ['auth', 'admin'] });
Router.set('/admin/clients', 'clients-admin', { middleware: ['auth', 'admin'] });
Router.set('/admin/financial', 'financial-admin', { middleware: ['auth', 'admin'] });
Router.set('/admin/financial-payment-history/:code', 'financial-admin-payment-history', { middleware: ['auth', 'admin'] });

// @SW_ROUTES - END

Router.set('*', '404');
