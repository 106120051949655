class FinancialAdminResource extends RESTFullResource {
    static endpoint () {
        return 'admin/financial';
    }
    static index (page = 1, filters) {
        const params = {
            page,
            ...filters
        };
        return this.request('GET', '/', params);
    }
    static listByBusiness (code, page, filters) {
        const params = {
            page,
            ...filters
        };

        return this.request('GET', code, params);
    }
    static xls (code) {
        return this.requestFile('GET', `${code}/report/xls`);
    }
}
