class AuthResource extends RESTFullResource {
    static endpoint () {
        return '';
    }

    static store (data, usertype) {
        if (usertype === 'CLIENT') {
            return this.request('POST', `client/${Env.get('AUTH_ENDPOINT')}`, data);
        } else if (usertype === 'ADMIN') {
            return this.request('POST', `admin/${Env.get('AUTH_ENDPOINT')}`, data);
        }
    }

    static destroy () {
        return this.request(Env.get('LOGOUT_ENDPOINT_METHOD'), Env.get('LOGOUT_ENDPOINT'));
    }

    static activate_account (token, usertype) {
        if (usertype === 'CLIENT') {
            return this.request('POST', `client/activate-account`, { token });
        } else if (usertype === 'ADMIN') {
            return this.request('POST', `admin/activate-account`, { token });
        }
    }
}