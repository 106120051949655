class FinancialResource extends RESTFullResource {
    static endpoint () {
        return 'client/financial';
    }
    static index (page = 1, filters) {
        const params = {
            page,
            ...filters
        };
        return this.request('GET', '/', params);
    }
    static show (id) {
        return this.request('GET', id);
    }
    static pay (code, data) {
        return this.request('GET', `${code}/pay`, data);
    }
    static confidenceRelease() {
        return this.request('POST', '/confidence-release', {});
    }
}
