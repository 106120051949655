class AuthProvider extends Auth {
    static async authenticate(credentials, keepLogin, usertype) {
        const data = await AuthResource.store(credentials, usertype);
        const user = this.normalizeUserData({ ...data, usertype});

        this.setUserData(user, keepLogin);
    }

    static normalizeUserData(userdata) {
        return new LoggedUser({
            code: userdata.code,
            name: userdata.name,
            email: userdata.email,
            phone: userdata.phone,
            role: userdata.role,
            permissions: userdata.permissions,
            profile_pic: userdata.profile_pic,
            active: userdata.active,
            can_access_financial: userdata.can_access_financial,
            token: userdata.token,
            usertype: userdata.usertype
        });
    }
}
