const debounce = (fn, delay) => {
    let timeout = null;

    return function () {
        const args = arguments;

        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(() => {
            fn.apply(null, args);
        }, delay);
    }
}

const mapToSelect = (response, valueKey = 'id', labelKey = 'name') => {
    const result = [];

    response.forEach((cur) => {
        if (cur instanceof Object && cur[valueKey] && cur[labelKey]) {
            result.push({value: cur[valueKey], label: cur[labelKey]});
        }
    });

    return result;
}

