const pageCanBeRendered = (page) => {
    const { role } = Auth.getUserData();

    const pagesPermissions = {
        'PATIO_MANAGER': ['DASHBOARD', 'FLOWS', 'INDICATORS-ANALYSIS-LIST']
    };

    if (role === 'PATIO_MANAGER') {
        return pagesPermissions[role].includes(page.toUpperCase());
    }

    return true;
}